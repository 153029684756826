var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "b-card",
        { staticClass: "mt-5 mx-auto", staticStyle: { "max-width": "420px" } },
        [
          _c("h2", [_vm._v("Войти в Гринсайт Кабинет")]),
          _vm._v(" "),
          _c(
            "b-form",
            {
              attrs: { method: "POST" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.send($event)
                }
              }
            },
            [
              _vm.error
                ? _c("p", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.error))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Логин", "label-for": "username" } },
                [
                  _c("b-form-input", {
                    attrs: { id: "username", required: "", name: "username" },
                    model: {
                      value: _vm.form.login,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "login", $$v)
                      },
                      expression: "form.login"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Пароль", "label-for": "password" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "password",
                      type: "password",
                      required: "",
                      name: "password"
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      model: {
                        value: _vm.form.remember,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "remember", $$v)
                        },
                        expression: "form.remember"
                      }
                    },
                    [_vm._v("Запомнить меня")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [_vm._v("Войти")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-link float-right",
                  attrs: { href: _vm.route("forgot-form") }
                },
                [_vm._v("Забыли пароль?")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("p", [_vm._v("Еще нет аккаунта?")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-outline-primary btn-block",
              attrs: { href: _vm.route("register") }
            },
            [_vm._v("Зарегистрироваться")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }