<template>
    <v-layout>
        <b-card style="max-width: 420px;" class="mt-5 mx-auto">
            <h2>Войти в Гринсайт Кабинет</h2>
            <b-form method="POST" @submit.prevent="send">
                <p v-if="error" class="text-danger">{{ error }}</p>

                <b-form-group label="Логин" label-for="username">
                    <b-form-input id="username" v-model="form.login" required name="username"/>
                </b-form-group>
                <b-form-group label="Пароль" label-for="password">
                    <b-form-input id="password" v-model="form.password" type="password" required name="password"/>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="form.remember">Запомнить меня</b-form-checkbox>
                </b-form-group>

                <button type="submit" class="btn btn-primary">Войти</button>
                <a class="btn btn-link float-right" :href="route('forgot-form')">Забыли пароль?</a>
            </b-form>

            <hr/>
            <p>Еще нет аккаунта?</p>
            <a :href="route('register')" class="btn btn-outline-primary btn-block">Зарегистрироваться</a>
        </b-card>
    </v-layout>
</template>

<script>

import Services from '../../../scripts/services/services.js';

export default {
    name: 'page-auth-login',
    data() {
        return {
            form: {
                login: '',
                password: '',
                remember: true,
            },
            error: '',
        }
    },
    methods: {
        send() {
            this.error = '';
            Services.net().post(this.route('login'), this.form).then(data => {
                window.location = data.url;
            }).catch(data => {
                this.error = data.errors || "Ошибка авторизации";
            })
        }
    }
};
</script>
